<template>
  <div class="features-container box-shadow row">
    <FeatureLink
      v-for="feature in features"
      :key="feature"
      :color="feature.color"
      :icon="feature.icon"
      :headline="feature.headline"
      :description="feature.description"
      :href="feature.href"
      @click="$emit('hideFeatures')"
    />
  </div>
</template>

<script>
import { useStore } from 'vuex'

import FeatureLink from './FeatureLink.vue'

export default {
  name: 'Features',
  emits: ['hideFeatures'],
  components: { FeatureLink },
  setup() {
    const store = useStore()

    const features = [
      {
        color: 'var(--education)',
        icon: 'fa-solid fa-clock',
        headline: 'nav-06',
        description: 'feat-01',
        href: `/${store.state.language}#time`,
      },
      {
        color: 'var(--valid)',
        icon: 'fa-solid fa-umbrella-beach',
        headline: 'nav-17',
        description: 'feat-02',
        href: `/${store.state.language}#absence`,
      },
      {
        color: 'var(--sick)',
        icon: 'fa-solid fa-file',
        headline: 'nav-18',
        description: 'feat-03',
        href: `/${store.state.language}#reporting`,
      },
      {
        color: 'var(--holiday)',
        icon: 'fa-solid fa-globe',
        headline: 'nav-07',
        description: 'feat-04',
        href: `/${store.state.language}#status`,
      },
    ]

    return {
      features,
    }
  },
}
</script>

<style lang="scss" scoped>
.features-container {
  position: absolute;
  background-color: var(--base);
  top: 50px;
  left: calc(50% - 460px);
  width: 570px;
  border-radius: 15px;
  padding: 25px;
  flex-wrap: wrap;

  @media (orientation: portrait) {
    position: relative;
    display: flex;
    flex-direction: column;
    width: calc(100% - 30px);
    padding: 15px;
    left: 0;
    top: 0;
  }
}
</style>
