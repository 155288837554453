<template>
  <div v-if="emit !== ''" @click="$emit(emit)" class="link row">
    <div v-if="icon !== ''" class="icon row">
      <i :class="icon"></i>
    </div>
    <span>{{ t(text) }}</span>
  </div>

  <router-link v-else-if="!external" :to="href" class="link row">
    <div v-if="icon !== ''" class="icon row">
      <i :class="icon"></i>
    </div>
    <span>{{ t(text) }}</span>
  </router-link>

  <a v-else :href="href" target="_blank" rel="noopener noreferrer" class="link row">
    <div v-if="icon !== ''" class="icon row">
      <i :class="icon"></i>
    </div>
    <span>{{ t(text) }}</span>
  </a>
</template>

<script>
export default {
  name: 'Link',
  props: {
    href: { type: String, default: '/en' },
    icon: { type: String, default: '' },
    text: { type: String, default: '' },
    external: { type: Boolean, default: false },
    emit: { type: String, default: '' },
  },
  setup() {},
}
</script>

<style lang="scss" scoped>
.link {
  color: var(--dark-10);
  font-size: 1.25rem;
  align-items: center;
  justify-content: start;
  font-weight: bold;
  letter-spacing: -1px;
  padding: 7px 15px;
  border-radius: 10px;
  cursor: pointer;

  @media only screen and (max-width: 450px) {
    font-size: 1.5rem;
  }

  .icon {
    i {
      color: var(--dark-10);
      margin-right: 10px;
    }

    @media only screen and (max-width: 450px) {
      width: 40px;
    }
  }

  &:hover,
  &:active {
    background-color: var(--highlight);
  }
}
</style>
