<template>
  <router-view class="container" />
  <Header />
  <CookieBanner />
  <Footer v-if="showFooter" />
</template>

<script>
import { ref, onMounted } from 'vue'
import { useStore } from 'vuex'

import Header from '@components/navigation/header/Header.vue'
import Footer from '@components/navigation/Footer.vue'
import CookieBanner from '@components/cookieBanner/CookieBanner.vue'

export default {
  name: 'App',
  components: { Header, Footer, CookieBanner },
  setup() {
    const store = useStore()
    const showFooter = ref(false)

    onMounted(async () => {
      await store.dispatch('ping')
      await store.dispatch('pingDemo')

      document.addEventListener('click', (event) => {
        const clickedElement = event.target
        const anchorEl =
          clickedElement.tagName.toLowerCase() === 'a'
            ? clickedElement
            : clickedElement.closest('a')
            ? clickedElement.closest('a')
            : null

        if (anchorEl) store.dispatch('saveClick', anchorEl.href)
      })

      setTimeout(() => {
        showFooter.value = true
      }, 100)
    })

    return {
      showFooter,
    }
  },
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anton&family=Rubik+Mono+One&display=swap');
@import '@/assets/variables.scss';

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
}

#app {
  width: 100%;
  margin: 0;
  color: var(--dark-10);
  overflow-x: hidden;

  .box-shadow {
    box-shadow: rgba(3, 8, 20, 0.1) 0px 0.15rem 0.5rem, rgba(2, 8, 20, 0.1) 0px 0.075rem 0.175rem;
  }

  .container {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    max-width: 1240px;

    @media only screen and (max-width: 450px) {
      width: 95%;
    }
  }

  .row {
    display: flex;
  }

  .column {
    display: flex;
    flex-direction: column;
  }

  .rotate {
    transform: rotate(180deg);
    transition: all 0.2s linear;
  }

  .hidden {
    display: none;
  }

  a {
    text-decoration: none;
    color: var(--dark-10);
  }

  i {
    transition: all 0.2 linear;
  }

  p {
    font-size: 1.1rem;
  }

  .line {
    width: 100%;
    border: 1px solid lightgray;
    margin: 10px 0;
  }
}
</style>
