<template>
  <router-link :to="href" class="feature-link">
    <div class="icon row">
      <i :class="icon" :style="{ color: color }"></i>
    </div>

    <div class="headline">
      <b>
        {{ t(headline) }}
      </b>
    </div>

    <div class="description">
      {{ t(description) }}
    </div>
  </router-link>
</template>

<script>
export default {
  name: 'FeatureLink',
  props: {
    href: { type: String, default: '' },
    color: { type: String, default: '' },
    icon: { type: String, default: '' },
    headline: { type: String, default: '' },
    description: { type: String, default: '' },
  },
  setup() {},
}
</script>

<style lang="scss" scoped>
.feature-link {
  width: 255px;
  padding: 10px 15px;
  margin: 5px 0;
  border-radius: 15px;
  display: grid;
  grid-template-rows: 0.3fr 0.7fr;
  grid-template-columns: 0.25fr 0.75fr;
  grid-template-areas:
    'icon headline'
    'icon description';

  &:hover {
    background-color: var(--highlight);
  }

  .icon {
    grid-area: icon;
    font-size: 2.5rem;
    align-items: center;
  }

  .headline {
    grid-area: headline;
    font-size: 1.1rem;
  }

  .description {
    grid-area: description;
    font-size: 0.9rem;
    color: var(--gray);
  }

  @media only screen and (max-width: 450px) {
    width: calc(100% - 30px);
  }
}
</style>
