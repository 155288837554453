<template>
  <div class="mobile-menu column" :class="{ show: showMenu }">
    <Link
      text="nav-02"
      icon="fa-solid fa-user"
      :href="`https://app.sharedex.co/auth/login?language=${$store.state.language}`"
      :external="true"
    />
    <Link
      @click="$emit('closeMenu')"
      text="nav-03"
      icon="fa-solid fa-user-plus"
      :href="`https://app.sharedex.co/auth/register?language=${$store.state.language}`"
      :external="true"
    />

    <div class="line"></div>

    <Link
      text="nav-01"
      icon="fa-regular fa-credit-card"
      :href="`/${$store.state.language}/pricing`"
      class="link row"
      @click="$emit('closeMenu')"
    />

    <div class="line"></div>

    <Features @click="$emit('closeMenu')" />

    <Languages @updateLanguage="$emit('updateLanguage', $event)" />
  </div>
</template>

<script>
import { ref } from 'vue'

import Link from '../components/Link.vue'
import Features from '../components/features/Features.vue'
import Languages from './components/Languages.vue'

export default {
  name: 'Mobile',
  emits: ['updateLanguage', 'closeMenu'],
  props: {
    showMenu: { type: Boolean, default: false },
  },
  components: { Link, Features, Languages },
  setup() {
    const showLanguages = ref(false)

    return {
      showLanguages,
    }
  },
}
</script>

<style lang="scss" scoped>
.mobile-menu {
  height: 100%;
  position: fixed;
  top: 0;
  left: -600px;
  width: calc(100% - 60px);
  max-width: 400px;
  height: calc(100% - 120px);
  z-index: 5;
  padding: 100px 30px 20px 30px;
  background-color: white;
  transition: left 0.2s linear;
  overflow-y: scroll;

  &.show {
    left: 0;
    transition: left 0.2s linear;
  }
}
</style>
