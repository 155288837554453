<template>
  <div class="footer column">
    <div class="container">
      <div class="logo column">
        <div class="row">
          <i class="fa-solid fa-circle-nodes"></i>
          <span>Sharedex</span>
        </div>
      </div>

      <div class="contact column">
        <h2>{{ t('nav-09') }}</h2>

        <a href="mailto:info@sharedex.co">
          <span>{{ t('nav-09') }}</span>
          <i class="fa-solid fa-envelope"></i>
        </a>
      </div>

      <div class="auth column">
        <h2>{{ t('nav-10') }}</h2>

        <a
          :href="`https://app.sharedex.co/auth/register?language=${$store.state.language}`"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i class="fa-solid fa-user-plus"></i>
          <span>{{ t('nav-03') }} </span>
        </a>
        <a
          :href="`https://app.sharedex.co/auth/login?language=${$store.state.language}`"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i class="fa-solid fa-right-to-bracket"></i>
          <span>{{ t('nav-02') }}</span>
        </a>
      </div>

      <div class="links column">
        <h2>{{ t('nav-11') }}</h2>

        <router-link :to="`/${$store.state.language}/legal`">
          <span>{{ t('nav-12') }}</span>
        </router-link>

        <router-link :to="`/${$store.state.language}/privacy`">
          <span>{{ t('nav-13') }}</span>
        </router-link>

        <router-link :to="`/${$store.state.language}/terms`">
          <span>{{ t('nav-14') }}</span>
        </router-link>
      </div>
    </div>

    <div class="rights row">
      <p>© 2024 Sharedex. All rights reserved.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MainFooter',
  setup() {
    return {}
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/variables.scss';

.footer {
  width: 100%;
  padding: 50px 0 100px 0;
  margin-top: 250px;
  background-color: var(--dark-10);
  align-items: center;
  justify-content: center;
  color: white;

  @media only screen and (max-width: 450px) {
    padding: 50px 0;
  }

  .container {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-areas: 'logo contact auth links';

    @media only screen and (max-width: 900px) {
      max-width: 450px !important;
      grid-template-rows: 0.5fr 1fr 1fr 1fr;
      grid-template-columns: 1fr;
      grid-template-areas:
        'logo'
        'contact'
        'auth'
        'links';
    }

    h2 {
      margin: 0 0 20px 0;
    }

    .logo {
      grid-area: logo;
      align-items: center;
      font-size: 2.5rem;
      margin-bottom: 20px;

      .row {
        align-items: center;

        i {
          margin-right: 10px;
        }

        p {
          margin: 0 0 5px 0;
          font-size: 1rem;
          padding-left: 10px;
          width: 100%;
        }
      }
    }

    .contact {
      grid-area: contact;
      align-items: center;

      a {
        padding: 5px 15px;
        border-radius: 25px;
        background-color: white;
        color: var(--purple-5);
        text-align: center;
        font-size: 1.25rem;

        i {
          margin-left: 10px;
        }
      }
    }

    .auth {
      grid-area: auth;
      align-items: flex-start;
      justify-content: flex-start;

      a {
        color: white !important;
        margin-bottom: 10px;
        font-size: 1.1rem;

        span:hover {
          text-decoration: underline;
        }

        i {
          margin-right: 10px;
        }
      }
    }

    .links {
      grid-area: links;

      a {
        color: white !important;
        margin-bottom: 10px;
      }

      a span:hover {
        text-decoration: underline;
      }
    }

    .auth,
    .links {
      @media only screen and (max-width: 450px) {
        width: 70%;
        margin: 0 auto;
      }
    }
  }

  .rights {
    p {
      margin-bottom: 0;
      color: white !important;

      @media only screen and (max-width: 450px) {
        font-size: 0.8rem !important;
      }
    }
  }
}
</style>
