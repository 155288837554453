export default {
  'reporting-main': {
    en: 'Reports',
    de: 'Reporting',
  },

  'reporting-sub-01': {
    en: 'Better Planning',
    de: 'Bessere Planbarkeit ',
  },
  'reporting-01': {
    en: 'Receive statistics on monthly absence days or average working hours.',
    de: 'Erhalte Statistiken über monatliche Abwesenheitstage oder die durchschnittlichen Arbeitsstunden.',
  },

  'reporting-sub-02': {
    en: 'User Reports',
    de: 'Nutzer Reports',
  },
  'reporting-02': {
    en: 'Detailed view of all absences for individual users, all in one place.',
    de: 'Alle Abwesenheiten einzelner Nutzer detailliert und auf einen Blick.',
  },

  'reporting-sub-03': {
    en: 'Always up to date',
    de: 'Immer up to date',
  },
  'reporting-03': {
    en: 'Get key metrics delivered to your email regularly and stay informed.',
    de: 'Erhalte die wichtigsten Kennzahlen regelmäßig per E-Mail und behalte den Überblick.',
  },
}
