export default {
  'time-main': {
    en: 'Time Tracking',
    de: 'Zeiterfassung',
  },
  'time-h-01': {
    en: 'Save Time & Money',
    de: 'Spare Zeit & Geld',
  },
  'time-b-01': {
    en: 'Don’t waste time. Track work and break times with just one click.',
    de: 'Verschwende keine Zeit. Erfasse Arbeits- und Pausenzeiten mit nur einem Klick.',
  },
  'time-h-02': {
    en: 'High Flexibility',
    de: 'Hohe Flexibilität',
  },
  'time-b-02': {
    en: 'All entries can be edited or delete later. This way, you stay in control.',
    de: 'Alle Einträge können nachträglich bearbeitet oder gelöscht werden. So behältst du die Oberhand.',
  },
  'time-h-03': {
    en: 'Easy Data Management',
    de: 'Einfaches Datenmanagement',
  },
  'time-b-03': {
    en: 'Export your data for stakeholders or import it from Excel files.',
    de: 'Exportiere deine Daten für Stakeholder oder importiere sie aus Excel Dateien.',
  },
}
