export default {
  'absence-main': {
    en: 'Absence Tracking',
    de: 'Abwesenheiten',
  },

  'absence-sub-01': {
    en: 'Efficiently Manage Absences',
    de: 'Abwesenheiten effizient erfassen',
  },
  'absence-01': {
    en: 'Quickly and easily manage absences such as illness, vacation, or training.',
    de: 'Verwalte Abwesenheiten wie Krankheit, Urlaub oder Fortbildungen schnell und einfach.',
  },

  'absence-sub-02': {
    en: 'All in One Place',
    de: 'Alles auf einen Blick',
  },
  'absence-02': {
    en: 'All absences are transferred to a team calendar and displayed based on permissions.',
    de: 'Alle Abwesenheiten werden in einen Team-Kalender übernommen und basierend auf Berechtigungen angezeigt.',
  },

  'absence-sub-03': {
    en: 'Stay informed',
    de: 'Bleib informiert',
  },
  'absence-03': {
    en: 'Responsible members are notified of requests and can process them accordingly.',
    de: 'Verantwortliche werden über Anträge informiert und können diese entsprechend bearbeiten.',
  },
}
