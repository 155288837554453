<template>
  <div class="navbar-container box-shadow row">
    <Logo @closeMenu="showMenu = false" />
    <ToggleBtn :showMenu="showMenu" @toggleMenu="showMenu = !showMenu" />
    <Desktop @updateLanguage="updateLanguage($event)" />
  </div>

  <Mobile :showMenu="showMenu" @closeMenu="showMenu = false" @updateLanguage="updateLanguage($event)" />
</template>

<script>
import { ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import Logo from './components/Logo.vue'
import ToggleBtn from './mobile/components/ToggleBtn.vue'
import Desktop from './desktop/Desktop.vue'
import Mobile from './mobile/Mobile.vue'

export default {
  name: 'MainNavbar',
  components: { Logo, ToggleBtn, Desktop, Mobile },
  setup() {
    const showMenu = ref(false)
    const route = useRoute()
    const router = useRouter()

    const updateLanguage = (language) => {
      let url = route.fullPath
      let updatedUrl = url.slice(0, 1) + language + url.slice(3)
      router.push(updatedUrl)

      showMenu.value = false
    }

    return {
      showMenu,
      updateLanguage,
    }
  },
}
</script>

<style lang="scss" scoped>
.navbar-container {
  position: fixed;
  top: 10px;
  background-color: white;
  width: calc(95% - 30px);
  padding: 0 15px;
  height: 70px;
  align-items: center;
  justify-content: space-between;
  z-index: 10;
  margin-left: calc(2.5%);
  border-radius: 25px;
  box-shadow: 0 0.15rem 0.5rem rgba(3, 8, 20, 0.1), 0 0.075rem 0.175rem rgba(2, 8, 20, 0.1);
}
</style>
