<template>
  <div class="desktop-menu links row">
    <div class="feature-links row">
      <Link @toggleFeatures="toggleFeatures" emit="toggleFeatures" icon="fa-solid fa-gears" text="nav-16" />
      <Link :href="`/${$store.state.language}/pricing`" icon="fa-regular fa-credit-card" text="nav-01" />

      <Features class="features" v-if="showFeatures" @hideFeatures="toggleFeatures" />
    </div>

    <div class="auth-links row">
      <Link href="https://app.sharedex.co/auth/login" text="nav-02" :external="true" />
      <Link
        @click="showMenu = false"
        :href="`https://app.sharedex.co/auth/register?language=${$store.state.language}`"
        text="nav-03"
        :external="true"
      />

      <Languages @updateLanguage="$emit('updateLanguage', $event)" />
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import { clickOutsideElement } from '@lib/helper.js'

import Link from '../components/Link.vue'
import Features from '../components/features/Features.vue'
import Languages from './components/Languages.vue'

export default {
  name: 'Desktop',
  emits: ['updateLanguage'],
  components: { Link, Features, Languages },
  setup() {
    const showFeatures = ref(false)

    const toggleFeatures = () => {
      showFeatures.value = !showFeatures.value

      if (showFeatures.value)
        clickOutsideElement('.features', () => {
          showFeatures.value = false
        })
    }

    return {
      showFeatures,
      toggleFeatures,
    }
  },
}
</script>

<style lang="scss" scoped>
.desktop-menu {
  width: calc(100% - 250px);
  position: relative;

  @media (orientation: portrait) or (max-width: 900px) {
    display: none !important;
  }

  .feature-links,
  .auth-links {
    width: 50%;
  }

  .feature-links,
  .auth-links {
    justify-content: end;
  }
}
</style>
